import React from 'react';
import {
  AbsoluteFill,
  spring,
  useCurrentFrame,
  useVideoConfig
} from 'remotion';
import {
  Animated,
  Animation,
  Fade,
  Move,
  Scale,
  Size
} from 'remotion-animated';
import ReactMarkdown from 'react-markdown';
import MarkdownRenderer from './previewMarkdownRenderer';

const TITLE_DURATION = 60;
const STATE_DURATION = 60;

const TextComponent = ({ textContent }) => {
  return <pre>{textContent}</pre>;
};

const RemotionTextSlide = ({ title, content, background, animationStyle }) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const animations = {
    fadeIn: {
      opacity: spring({ frame, fps, from: 0, to: 1, durationInFrames: 30 })
    },
    slideUp: {
      transform: `translateY(${spring({
        frame,
        fps,
        from: 100,
        to: 0,
        durationInFrames: 30
      })}%)`
    }
    // Add more animations as needed
  };

  return (
    <AbsoluteFill
      className="items-center justify-center"
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%'
      }}
    >
      <Animated
        absolute
        animations={[
          Scale({ by: 1.75, initial: 10 }),
          Fade({ to: 1, initial: 0 })
        ]}
        style={{
          top: '10%'
        }}
      >
        <div className="text-gray-200 text-5xl font-bold leading-relaxed">
          {title}
        </div>
      </Animated>

      <AbsoluteFill className="justify-center" style={{ top: '5%' }}>
        <code className="text-gray-200 text-4xl pl-14 gap-10 grid justify-center">
          {content.map((textContent, index) => (
            <TextComponent key={index} textContent={textContent} />
          ))}
        </code>
      </AbsoluteFill>
    </AbsoluteFill>
  );
};

export default RemotionTextSlide;
