import React from 'react';
import styles from './generate.module.scss';
import { Image } from 'antd';

export default function Background({ selectedBackground, onBackgroundSelect }) {
  console.log(selectedBackground);

  const imageData = [
    {
      id: 1,
      image_name: 'bg_1'
    },
    {
      id: 2,
      image_name: 'bg_2'
    },
    {
      id: 3,
      image_name: 'bg_3'
    },
    {
      id: 4,
      image_name: 'bg_4'
    },
    {
      id: 5,
      image_name: 'bg_5'
    },
    {
      id: 6,
      image_name: 'bg_6'
    },
    {
      id: 7,
      image_name: 'bg_7'
    },
    {
      id: 8,
      image_name: 'bg_8'
    },
    {
      id: 9,
      image_name: 'bg_9'
    },
    {
      id: 10,
      image_name: 'bg_10'
    },
    {
      id: 11,
      image_name: 'bg_11'
    },
    {
      id: 12,
      image_name: 'bg_12'
    },
    {
      id: 13,
      image_name: 'bg_13'
    },
    {
      id: 14,
      image_name: 'bg_14'
    },
    {
      id: 15,
      image_name: 'bg_15'
    },
    {
      id: 16,
      image_name: 'bg_16'
    },
    {
      id: 17,
      image_name: 'bg_17'
    },
    {
      id: 18,
      image_name: 'bg_18'
    },
    {
      id: 19,
      image_name: 'bg_19'
    },
    {
      id: 20,
      image_name: 'bg_20'
    },
    {
      id: 21,
      image_name: 'bg_21'
    },
    {
      id: 22,
      image_name: 'bg_22'
    },
    {
      id: 23,
      image_name: 'bg_23'
    },
    {
      id: 24,
      image_name: 'bg_24'
    }
  ];

  return (
    <div className={styles.backgroundWrapper}>
      {imageData.map((item, index) => (
        <div
          key={item.id}
          className={`${styles.bgCard} ${
            selectedBackground === item.image_name ? styles.selected : ''
          }`}
          onClick={() => onBackgroundSelect(item)}
        >
          <Image
            preview={false}
            src={require(`../../../asset/images/background/${item.image_name}.gif`)}
          />
        </div>
      ))}
    </div>
  );
}
