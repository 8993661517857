import React from 'react';
import styles from './generate.module.scss';
import { Select, Input } from 'antd';
import { VaTitle } from '../typography';
import { SoundOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
const { TextArea } = Input;

const helpContent = (
  <div>
    <p>
      Feel free to <strong>customize</strong> your audio narration
    </p>
    <p>text based on your needs.</p>
  </div>
);

export default function Audio({
  onAudioSelect,
  selectedAudio,
  selectedText,
  onTextChange
}) {
  console.log(selectedAudio, selectedText);

  const handleChange = (value) => {
    console.log('Selected Value:', value);
    onAudioSelect(value); // Update state or perform other actions
  };
  return (
    <div className={styles.audioWrapper}>
      <div className={styles.audioSelect}>
        <label>Select Audio</label>
        <Select
          // defaultValue={selectedAudio}
          value={selectedAudio}
          style={{
            width: '80%'
          }}
          options={[
            {
              value: 'A1',
              label: 'A1'
            },
            {
              value: 'A2',
              label: 'A2'
            },
            {
              value: 'A3',
              label: 'A3'
            },
            {
              value: 'A4',
              label: 'A4'
            },
            {
              value: 'A5',
              label: 'A5'
            },
            {
              value: 'A6',
              label: 'A6'
            }
          ]}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}
