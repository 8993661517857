import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { makeApiRequest } from '../../../shared/api';

export const createCourse = createAsyncThunk(
  'course/createCourse',
  async (courseData, thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', {
        action: 'generate_course',
        ...courseData
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getCourseById = createAsyncThunk(
  'course/getCourseById',
  async (courseData, thunkAPI) => {
    console.log(courseData);

    try {
      const response = await makeApiRequest('POST', {
        action: 'get_course',
        ...courseData
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteVideoById = createAsyncThunk(
  'course/deleteVideoById',
  async (videoId, thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', {
        action: 'delete_course_video',
        ...videoId
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteShortVideoById = createAsyncThunk(
  'course/deleteShortVideoById',
  async (videoId, thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', {
        action: 'delete_video',
        ...videoId
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const generatePptById = createAsyncThunk(
  'course/generatePptById',
  async (videoId, thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', {
        action: 'generate_powerpoint',
        ...videoId
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getCoursesList = createAsyncThunk(
  'course/getCoursesList',
  async (thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', { action: 'get_courses' });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getVideosList = createAsyncThunk(
  'course/getVideosList',
  async (thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', { action: 'get_videos' });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const courseSlice = createSlice({
  name: 'course',
  initialState: {
    course: null,
    status: 'idle',
    error: null,
    coursesList: null,
    coursesListStatus: 'idle',
    coursesListError: null,
    videosList: null,
    videosListStatus: 'idle',
    videosListError: null
  },
  reducers: {
    clearCourseState: (state) => {
      state.course = null;
      state.status = 'idle';
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCourse.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCourse.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.course = action.payload;
      })
      .addCase(createCourse.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getCourseById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCourseById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.course = action.payload;
      })
      .addCase(getCourseById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getCoursesList.pending, (state) => {
        state.coursesListStatus = 'loading';
      })
      .addCase(getCoursesList.fulfilled, (state, action) => {
        state.coursesListStatus = 'succeeded';
        state.coursesList = action.payload;
      })
      .addCase(getCoursesList.rejected, (state, action) => {
        state.coursesListStatus = 'failed';
        state.coursesListError = action.payload;
      })
      .addCase(getVideosList.pending, (state) => {
        state.videosListStatus = 'loading';
      })
      .addCase(getVideosList.fulfilled, (state, action) => {
        state.videosListStatus = 'succeeded';
        state.videosList = action.payload;
      })
      .addCase(getVideosList.rejected, (state, action) => {
        state.videosListStatus = 'failed';
        state.videosListError = action.payload;
      });
  }
});

export const { clearCourseState } = courseSlice.actions;

export default courseSlice.reducer;
