import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './courseslist.module.scss';
import { Progress, Button, Image, Popover, Popconfirm } from 'antd';
import { useDispatch } from 'react-redux';
import { VaTitle } from '../typography';
import { Link } from 'react-router-dom';
import { getVideoById } from '../../../pages/courseIndex/redux/topicContentSlice';
import {
  getVideosList,
  deleteShortVideoById
} from '../../../pages/courses/redux/courseSlice';
import { EditOutlined, EyeFilled, DeleteOutlined } from '@ant-design/icons';
import { previewVideoContent } from 'pages/courseIndex/redux/previewVideoSlice';
import toast from 'react-hot-toast';

export default function VideosList(props) {
  const dispatch = useDispatch();
  const {
    data,
    isProgress,
    showGenerateModal,
    showPreviewModal,
    videoName,
    handlePreviewVideoOpen,
    shortData
  } = props;
  console.log(data);
  const [hovered, setHovered] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [selectedShortVideoId, setSelectedShortVideoId] = useState(null);

  const generateVideo = (data) => {
    console.log(data);
    videoName(data?.title);
    dispatch(getVideoById({ video_id: data?.video_id }));
    showGenerateModal();
  };

  const playVideo = (topicData) => {
    shortData(topicData);
    console.log(topicData);

    videoName(topicData?.title);
    showPreviewModal();
  };

  const hoveredContent = (data) => {
    if (data && data.video_status === 'VIDEO_UPLOADED') {
      return <div>Video is generated, please preview the video.</div>;
    } else if (
      data &&
      (data.video_status === 'QUEUED' ||
        data.video_status === 'PROCESSING_STARTED' ||
        data.video_status === 'AUDIO_GENERATED' ||
        data.video_status === 'SCENE_VIDEOS_GENERATED' ||
        data.video_status === 'FINAL_VIDEO_GENERATED')
    ) {
      return <p>Video is being generated</p>;
    } else if (data && data.video_status === 'NO_VIDEO') {
      return <div>Content is Generated, but Video is not yet generated.</div>;
    } else {
      return <div>Content is not yet generated. please generate Content.</div>;
    }
  };

  const handleHoverChange = (open, topicId) => {
    if (open) {
      setHoveredRow(topicId);
    } else {
      setHoveredRow(null);
    }
  };

  const deleteVideo = async (event, topicId) => {
    event.preventDefault();
    setConfirmLoading(true);

    // Show a loading toast while the deletion is in progress
    const loadingToastId = toast.loading('Deleting video...');

    // Dispatch deleteShortVideoById and wait for it to complete
    const resultAction = await dispatch(
      deleteShortVideoById({ video_id: topicId })
    );

    // Check if the deletion was successful before making another API call
    if (deleteShortVideoById.fulfilled.match(resultAction)) {
      setOpen(false);
      setConfirmLoading(false);
      setSelectedShortVideoId(null);
      // If successful, update the loading toast to a success message
      toast.success('Video deleted successfully!', { id: loadingToastId });
      // Dispatch getCourseById to fetch the updated course data
      dispatch(getVideosList());
    } else {
      // If the deletion failed, update the loading toast to an error message
      toast.error(
        `Failed to delete video: ${resultAction.payload || resultAction.error.message
        }`,
        { id: loadingToastId }
      );
    }
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (event, videoId) => {
    setOpen(true);
    setSelectedShortVideoId(videoId);
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
    setSelectedShortVideoId(null);
  };

  return (
    <div className={styles.wrapper}>
      {data.map((item) => (
        <Link key={item.id} className={styles.card}>
          <Image
            preview={false}
            src={require(`../../../asset/images/course.png`)}
          />
          <div className={styles.footer}>
            <div className={styles.title}>
              <VaTitle
                level={5}
                ellipsis={{
                  rows: 2
                }}
                text={item?.title}
              />
            </div>
            <div className={styles.actions}>
              <Button
                type="text"
                disabled={
                  item.video_status === 'QUEUED' ||
                  item.video_status === 'PROCESSING_STARTED' ||
                  item.video_status === 'AUDIO_GENERATED' ||
                  item.video_status === 'SCENE_VIDEOS_GENERATED' ||
                  item.video_status === 'FINAL_VIDEO_GENERATED'
                }
                icon={<EditOutlined />}
                onClick={() => generateVideo(item)}
              />
              <Popconfirm
                title="Delete Shorts"
                description={`Do you to delete the Short: ${item.topic_title}.`}
                open={selectedShortVideoId === item.video_id}
                onConfirm={(event) => deleteVideo(event, item?.video_id)}
                okButtonProps={{
                  loading: confirmLoading
                }}
                onCancel={handleCancel}
              >
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={(event) => showPopconfirm(event, item?.video_id)}
                />
              </Popconfirm>
              <Popover
                style={{ width: 500 }}
                content={hoveredContent(item)}
                title="Video Status"
                trigger="hover"
                open={hoveredRow === item.video_id}
                onOpenChange={(open) => handleHoverChange(open, item.video_id)}
              >
                <Button
                  type="text"
                  disabled={item?.progress < 100}
                  icon={<EyeFilled />}
                  onClick={() => playVideo(item)}
                />
              </Popover>
            </div>
            <div style={{ fontSize: '10px', color: 'black', fontWeight: '300', marginTop: '10px' }}>
              <strong>Created On : </strong>
              <span style={{ color: '#1890ff', fontWeight: 'bold' }}>
                {item?.created_on && !isNaN(new Date(item.created_on).getTime()) ? (
                  `${new Date(item.created_on).getDate()}-${new Date(item.created_on).toLocaleString('default', { month: 'short' })}-${new Date(item.created_on).getFullYear()}`
                ) : 'N/A'}
              </span>
            </div>
            <div className={styles.title}>
              <Progress
                percent={item.progress}
                strokeColor="#1890ff"
                trailColor="#f0f0f0"
              />
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}
