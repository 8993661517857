import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import styles from "./generate.module.scss";
import { VaText, VaTitle } from "../typography";
import {
  Image,
  Input,
  Tabs,
  message,
  Spin,
  Progress,
  Button,
  Modal,
  Form,
  Popconfirm,
  Select,
} from "antd";
import Background from "./background";
import Animations from "./animations";
import Audio from "./audio";
import Images from "./images";
import ContentText from "./contentText";
import AudioText from "./audioText";
import Avatar from "./avatar";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";
import {
  FileTextOutlined,
  PlaySquareOutlined,
  SwitcherOutlined,
  QuestionCircleOutlined,
  DeleteFilled,
  CloseCircleOutlined,
  PlusOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  generateVideo,
  updateTopicContent,
  deleteScenes,
  createScenes,
} from "../../../pages/courseIndex/redux/topicContentSlice";
import axios from "axios";
import toast from "react-hot-toast";
import RemotionTextSlide from "./RemotionTextSlide";
import RemotionTextImageSlide from "./RemotionTextImageSlide";
import { Player, Thumbnail } from "@remotion/player";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { generatePptById } from "../../../pages/courses/redux/courseSlice";
import { sceneTypeToComponent } from "./sceneTypeToComponent";

const { TextArea } = Input;

const Generate = forwardRef((props, ref) => {
  console.log(props);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const localRef = useRef(null);
  const preiewRef = useRef(null);
  const leftColRef = useRef(null);
  let topicDetails = useSelector((state) => state.topic.topicData);
  const status = useSelector((state) => state.topic.status);
  const error = useSelector((state) => state.topic.error);
  console.log(topicDetails, status, error);

  const [messageApi] = message.useMessage();

  const [selectedTopicData, setSelectedTopicData] = useState(null);
  const [scenesData, setScenesData] = useState([]);
  const [selectedScene, setSelectedScene] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedText, setSelectedText] = useState("");
  const [selectedMarkupText, setSelectedMarkupText] = useState("");
  const [selectedSceneIndex, setSelectedSceneIndex] = useState(null);
  const [selectedBackground, setSelectedBackground] = useState("bg_1");
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [selectedAnimation, setSelectedAnimation] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [selectedFileList, setSelectedFileList] = useState(null);
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(null);
  const [imageId, setImageId] = useState(null);
  const [scale, setScale] = useState(1);
  const [isApplyAllDisabled, setIsApplyAllDisabled] = useState(false);
  const [negativeMargin, setNegativeMargin] = useState(0); // Adjust if needed
  const [isSceneModalOpen, setIsSceneModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteSelectedScene, setDeleteSelectedScene] = useState(null);
  const [sceneType, setSceneType] = useState(null);
  const [actionType, setActionType] = useState("");

  useEffect(() => {
    if (status === "succeeded" && typeof topicDetails?.data !== "string") {
      console.log(topicDetails.data);

      setSelectedTopicData(topicDetails.data);
      let scenesDataList = topicDetails.data.scenes;
      let sortedScenes = sortScenes(scenesDataList);
      console.log(sortedScenes);
      setScenesData(sortedScenes);
      setSelectedScene(sortedScenes[0]);
      setSelectedSceneIndex(sortedScenes[0].scene_index);
      setSelectedTitle(sortedScenes[0].scene_title);
      setSelectedText(removeSpace(sortedScenes[0].scene_narration));
      setSelectedMarkupText(removeSpace(sortedScenes[0].scene_text));
      setSelectedAudio(sortedScenes[0].scene_audio_template);
      setSelectedBackground(sortedScenes[0].scene_bg_template);
      setSelectedAnimation(sortedScenes[0].scene_style_template);
      setImageId(sortedScenes[0].scene_image);
      setSelectedAvatar(sortedScenes[0].scene_avatar);
      setSceneType(sortedScenes[0].scene_type);
      if (sortedScenes[0].scene_image === "") {
        setSelectedFileList([]);
      } else {
        // Incase of content slide - make it a 'CONTENT_SLIDE_WITH_IMAGE'
        if (sceneType == "CONTENT_SLIDE")
          setSceneType("CONTENT_SLIDE_WITH_IMAGE");
      }
    }
  }, [status, topicDetails]);

  useImperativeHandle(ref, () => ({
    updateScene: () => {
      // Logic for updating the scene
      console.log("Scene updated from Generate component");
      handleUpdate();
    },
    generateVideoContent: () => {
      console.log("inside of the generating video content->>>>");
      handleGenerateVideo();
    },
    generatePptContent: () => {
      console.log("inside of the generating ppt content->>>>");
      handleDownloadPpt();
    },
  }));

  useEffect(() => {
    console.log(leftColRef);
    console.log(preiewRef);

    const updateScale = () => {
      if (leftColRef?.current && preiewRef?.current) {
        const colWidth = leftColRef.current.offsetWidth;
        const prevWidth = preiewRef.current.offsetWidth;
        const newWidth = colWidth / prevWidth;

        setScale(newWidth);
        // Optionally adjust margin to center the scaled content if necessary
        setNegativeMargin(0); // 50% for centering
      }
    };

    const checkAndSetScale = () => {
      if (leftColRef.current && preiewRef.current) {
        updateScale();
      } else {
        setTimeout(checkAndSetScale, 100); // Retry after a short delay if refs are not available
      }
    };

    checkAndSetScale(); // Initial call to set the scale
    window.addEventListener("resize", updateScale); // Adjust on resize

    return () => {
      window.removeEventListener("resize", updateScale);
    };
  }, []);

  const handleMarkdownContent = (text, action, sceneType = "") => {
    if (text) {
      if (action === "insert") {
        console.log(text);
        if (sceneType == "CODE_SLIDE") {
          return text;
        } else {
          let textSentencesTrimmed = text.split("\n").map((s) => s.trim());
          return textSentencesTrimmed;
        }
      } else {
        return "";
      }
    } else {
      return text;
    }
  };
  //Apply All Function
  const handleApplyAll = () => {
    setIsApplyAllDisabled(false);
    const selectedBackground = scenesData[selectedSceneIndex].scene_bg_template;
    const updatedScenes = scenesData.map((scene) => ({
      ...scene,
      scene_bg_template: selectedBackground,
    }));

    setScenesData(updatedScenes);
    message.success("Selected Background applied for all Scenes!", 5);
    setIsApplyAllDisabled(true);
  };

  const handleBackgroundSelect = (image) => {
    setIsApplyAllDisabled(false);
    setSelectedBackground(image.image_name);
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id
        ? { ...s, scene_bg_template: image.image_name }
        : s
    );
    setScenesData(updatedScenes);
  };

  const handleAnimationSelect = (animation) => {
    setIsApplyAllDisabled(true);
    setSelectedAnimation(animation.image_name);
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id
        ? { ...s, scene_style_template: animation.image_name }
        : s
    );
    setScenesData(updatedScenes);
  };

  const handleAvatarSelect = (image) => {
    console.log(image);
    if (image && image.image_name === 'none') {
      // Do nothing
      setSelectedAvatar(null);
    }
    else {
      setSelectedAvatar(image.image_name);
    }
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id
        ? { ...s, scene_avatar: image.image_name }
        : s
    );
    setScenesData(updatedScenes);
  };

  const handleAudioChange = (value) => {
    setSelectedAudio(value);
    console.log(value);

    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id ? { ...s, scene_audio_template: value } : s
    );
    setScenesData(updatedScenes);
  };

  const onTextChange = (event) => {
    setSelectedText(event.target.value);
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id
        ? { ...s, scene_narration: event.target.value }
        : s
    );
    setScenesData(updatedScenes);
  };

  const handleFileChange = async ({ fileList }) => {
    // Log fileList to debug
    console.log("File List:", fileList);

    const updatedFileList = Array.isArray(fileList) ? fileList : [];
    const file = updatedFileList[0]?.originFileObj;

    if (file) {
      console.log("File Details:", file);

      const newFileName = selectedScene.scene_id;
      const renamedFile = new File([file], newFileName, { type: file.type });

      let scene = scenesData[selectedSceneIndex];
      const updatedScenes = scenesData.map((s) =>
        s.scene_id === scene.scene_id ? { ...s, scene_image: newFileName } : s
      );
      setScenesData(updatedScenes);

      const formData = new FormData();
      Object.keys(selectedScene.upload_url.fields).forEach((key) => {
        formData.append(key, selectedScene.upload_url.fields[key]);
      });
      formData.append("file", renamedFile);

      try {
        const response = await axios.post(
          selectedScene.upload_url.url,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 204) {
          // setSelectedFileList([]);
          setImageId(newFileName);
        } else {
          message.error("File upload failed");
        }
      } catch (error) {
        message.error("File upload error: " + error.message);
      }
    } else {
      setSelectedFileList((prevState) =>
        prevState.map((item) =>
          item.hasOwnProperty(selectedScene.scene_id)
            ? { [selectedScene.scene_id]: null }
            : item
        )
      );
    }
  };

  const handleImageRemove = async () => {
    console.log("File deleted successfully");
    handleMarkdownContent(selectedMarkupText, "delete");
    // Update scenesData to remove the image
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id ? { ...s, scene_image: "" } : s
    );
    setScenesData(updatedScenes);

    // Reset the image ID and file list
    setImageId(null);
    setSelectedFileList((prevState) =>
      prevState.map((item) =>
        item.hasOwnProperty(selectedScene.scene_id)
          ? { [selectedScene.scene_id]: null }
          : item
      )
    );
  };

  const onMarkupTextChange = (event) => {
    setSelectedMarkupText(event.target.value);
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id
        ? { ...s, scene_text: event.target.value }
        : s
    );
    setScenesData(updatedScenes);
  };

  const handleSceneTitleChange = (event) => {
    console.log(event);
    setSelectedTitle(event.target.value);
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id
        ? { ...s, scene_title: event.target.value }
        : s
    );
    setScenesData(updatedScenes);
  };

  const handleSceneTypeChange = (event) => {
    console.log(event);

    setSceneType(event);
    let scene = scenesData[selectedSceneIndex];
    const updatedScenes = scenesData.map((s) =>
      s.scene_id === scene.scene_id ? { ...s, scene_type: event } : s
    );
    console.log(updatedScenes);

    setScenesData(updatedScenes);
  };

  const itemsTextComp = [
    {
      key: "1",
      label: "Content Text",
      children: (
        <ContentText
          selectedBackground={selectedBackground}
          onBackgroundSelect={handleBackgroundSelect}
          selectedMarkupText={selectedMarkupText}
          onMarkupTextChange={onMarkupTextChange}
        />
      ),
    },
    {
      key: "2",
      label: "Audio Text",
      children: (
        <AudioText selectedText={selectedText} onTextChange={onTextChange} />
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: "Background",
      children: (
        <Background
          selectedBackground={selectedBackground}
          onBackgroundSelect={handleBackgroundSelect}
        />
      ),
    },
    {
      key: "2",
      label: "Animations",
      children: (
        <Animations
          selectedAnimation={selectedAnimation}
          onAnimationSelect={handleAnimationSelect}
        />
      ),
    },
    {
      key: "3",
      label: "Avatar",
      children: (
        <Avatar
          selectedAvatar={selectedAvatar}
          onAvatarSelect={handleAvatarSelect}
        />
      ),
    },
    {
      key: "4",
      label: "Audio",
      children: (
        <Audio
          onAudioSelect={handleAudioChange}
          selectedAudio={selectedAudio}
          selectedText={selectedText}
          onTextChange={onTextChange}
        />
      ),
    },
    {
      key: "5",
      label: "Images",
      children: (
        <Images
          onFileSelect={handleFileChange}
          onFileRemove={handleImageRemove}
          fileList={selectedFileList}
          currentSceneImageName={imageId}
        />
      ),
    },
  ];

  const handleCardClick = (scene) => {
    setSelectedScene(scene);
    setSelectedSceneIndex(scene.scene_index);
    setSelectedTitle(scene.scene_title);
    setSelectedText(removeSpace(scene.scene_narration));
    setSelectedMarkupText(scene.scene_text);
    setSelectedAudio(scene.scene_audio_template);
    setSelectedBackground(scene.scene_bg_template);
    setSelectedAnimation(scene.scene_style_template);
    setSceneType(scene.scene_type);
    setSelectedAvatar(scene.scene_avatar);

    setImageId(scene.scene_image);
    if (scene.scene_image === "") {
      setSelectedFileList([]);
    } else {
      // Incase of content slide - make it a 'CONTENT_SLIDE_WITH_IMAGE'
      if (sceneType == "CONTENT_SLIDE")
        setSceneType("CONTENT_SLIDE_WITH_IMAGE");
    }
  };

  const removeSpace = (text) => {
    if (text) {
      return text.replace(/\s+/g, " ");
    } else {
      return "";
    }
  };

  const handleUpdate = async () => {
    let updSceneData = { scenes: scenesData }; //[...filterData, updatedSceneData] };
    let updatedTopicData = Object.assign({}, selectedTopicData, updSceneData);
    const asyncAction = new Promise((resolve, reject) => {
      dispatch(updateTopicContent(updatedTopicData));
      resolve();
    });
    asyncAction
      .then(() => {
        setSelectedTopicData(updatedTopicData);
        toast.success(<>Scenes getting updated successfully</>, {
          style: { fontSize: "15px", autoClose: 5000, marginRight: "150px" }, // You can still adjust the font size if needed
        });
      })
      .catch((error) => {
        // Handle errors if necessary
        console.error("Error:", error);
        toast.error(
          <>
            Oh Sorry you cannot able to update the template, please try again.
          </>,
          {
            style: { fontSize: "15px", autoClose: 5000, marginRight: "150px" }, // You can still adjust the font size if needed
          }
        );
      })
      .finally(() => {
        // Reset loading state regardless of success or failure
      });
  };
  const handleGenerateVideo = (e) => {
    const asyncAction = new Promise((resolve, reject) => {
      dispatch(generateVideo({ video_id: topicDetails?.data?.video_id }));

      resolve();
    });

    asyncAction
      .then(() => {
        // toast.success(
        //   'Generating video is inprogress. please watch the video after few minutes later',
        //   5.0
        // );
        toast.success(
          <>
            Generating video is inprogress. please, <br />
            watch the video after few minutes later
          </>,
          {
            style: {
              fontSize: "15px",
              autoClose: 5000,
              marginRight: "600px",
              lineHeight: "25px",
            }, // You can still adjust the font size if needed
          }
        );
        if (props.handleCancel) {
          props.handleCancel(); // Close the modal after generating the video
        }
      })
      .catch((error) => {
        // Handle errors if necessary
        console.error("Error:", error);
        messageApi.error(
          "Oh Sorry video is not getting generated, please try again.",
          2.5
        );
      })
      .finally(() => {
        // Reset loading state regardless of success or failure
      });
  };

  const handleDownloadPpt = async (e) => {
    // Generate ppt content based on the course data
    // e.preventDefault();

    // Show a loading toast while the downloading is in progress
    const loadingToastId = toast.loading("Downloading Powerpoint...");

    // Dispatch deleteVideoById and wait for it to complete
    const resultAction = await dispatch(
      generatePptById({
        video_id: topicDetails?.data?.video_id,
      })
    );
    console.log(resultAction);

    // Check if the generatePptById was successful
    if (generatePptById.fulfilled.match(resultAction)) {
      // Extract the data from the resultAction
      const { data } = resultAction.payload;
      if (data?.url) {
        // Redirect to the download URL
        window.location.href = data.url;
        // Update the toast to a success message
        toast.success("PowerPoint Downloaded successfully!", {
          id: loadingToastId,
        });
      } else {
        throw new Error("Failed to get download URL");
      }
    } else {
      // If the generate ppt failed, update the loading toast to an error message
      toast.error(
        `Failed to download PowerPoint: ${
          resultAction.payload?.message || resultAction.error.message
        }`,
        { id: loadingToastId }
      );
    }
  };

  const selectedImage = (scene) => {
    setSelectedBackgroundImage(
      require(`../../../asset/images/background/${scene.scene_bg_template}.gif`)
    );
  };

  const helpContent = (
    <div>
      <p>
        Hey there! Feel free to <strong>edit</strong> and <strong>view </strong>
        your
      </p>
      <p>text based on your needs.</p>
    </div>
  );

  const helpSceneContent = (
    <div>
      <p>
        You can select individual scenes for <strong>edit</strong> and{" "}
        <strong>preview</strong>.{" "}
      </p>
      <p>Customize each scene to meet your needs.</p>
    </div>
  );

  const deleteScene = async (event, sceneId) => {
    event.stopPropagation(); // Prevent triggering the card click handler
    setConfirmLoading(true);

    dispatch(
      deleteScenes({ video_id: selectedTopicData?.video_id, scene_id: sceneId })
    );

    let removeScene = scenesData.filter((scene) => scene.scene_id !== sceneId);

    let reindexedScenes = removeScene.map((scene, index) => ({
      ...scene,
      scene_index: index,
    }));
    setConfirmLoading(false);
    setDeleteSelectedScene(null);
    let sortedScenes = sortScenes(reindexedScenes);
    setScenesData(sortedScenes);

    setSelectedScene(sortedScenes[0]);
    setSelectedSceneIndex(sortedScenes[0].scene_index);
    setSelectedTitle(sortedScenes[0].scene_title);
    setSelectedText(removeSpace(sortedScenes[0].scene_narration));
    setSelectedMarkupText(removeSpace(sortedScenes[0].scene_text));
    setSelectedAudio(sortedScenes[0].scene_audio_template);
    setSelectedBackground(sortedScenes[0].scene_bg_template);
    setSelectedAnimation(sortedScenes[0].scene_style_template);
    setImageId(sortedScenes[0].scene_image);
    setSceneType(sortedScenes[0].scene_type);
    setSelectedAvatar(sortedScenes[0].scene_avatar);
    console.log(sceneTypeToComponent[sceneType]);
    if (sortedScenes[0].scene_image === "") {
      setSelectedFileList([]);
    } else {
      // Incase of content slide - make it a 'CONTENT_SLIDE_WITH_IMAGE'
      if (sceneType == "CONTENT_SLIDE")
        setSceneType("CONTENT_SLIDE_WITH_IMAGE");
    }
  };

  const sortScenes = (unsortedData) => {
    return unsortedData.slice().sort((a, b) => a.scene_index - b.scene_index);
  };

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (destination.index === source.index) return; // Item dropped in the same place

    const reorderedScenes = Array.from(scenesData);
    const [movedScene] = reorderedScenes.splice(source.index, 1);
    reorderedScenes.splice(destination.index, 0, movedScene);

    setScenesData(reorderedScenes);
  };

  const handleCancel = () => {
    setIsSceneModalOpen(false);
  };

  const showSceneModal = () => {
    setIsSceneModalOpen(true);
  };

  const onFinishScene = async (values) => {
    let ai_generated_value = "false";
    if (actionType === "GenerateByAI") {
      ai_generated_value = "true";
    }

    console.log("Received values of form:", values);
    console.log(scenesData);
    console.log(selectedTopicData);
    setLoading(true);
    try {
      console.log(selectedTopicData?.scenes.length);
      const response = await dispatch(
        createScenes({
          video_id: selectedTopicData?.video_id,
          topic: values.sceneName,
          scene_type: values.sceneType[0],
          scene_index: selectedTopicData?.scenes.length,
          ai_generate: ai_generated_value,
        })
      );
      // Handle the response here
      console.log("Scene created successfully:", response);
      let newScene = response.payload.data;
      console.log(newScene);

      newScene = {
        ...newScene,
        scene_index: selectedTopicData?.scenes.length,
      };
      console.log(newScene);
      console.log(scenesData.length);
      let updatedScenes = [
        ...scenesData, // Include existing scenes
        newScene,
      ];
      console.log(updatedScenes.length);
      console.log(updatedScenes);

      if (
        response.payload.data ===
        "Unable to generate scene at this point. Try after sometime!"
      ) {
        toast.error(
          "Unable to generate scene at this point. Try after sometime!"
        );
      } else {
        console.log(updatedScenes);
        let sortedScenes = sortScenes(updatedScenes);
        console.log(sortedScenes);
        setScenesData(sortedScenes);
        let createdData = sortedScenes.filter(
          (scene) => scene?.scene_id === response?.payload?.data?.scene_id
        );
        console.log(createdData);

        setSelectedScene(createdData[0]);
        setSelectedSceneIndex(createdData[0].scene_index);
        setSelectedTitle(createdData[0].scene_title);
        setSelectedText(removeSpace(createdData[0].scene_narration));
        setSelectedMarkupText(removeSpace(createdData[0].scene_text));
        setSelectedAudio(createdData[0].scene_audio_template);
        setSelectedBackground(createdData[0].scene_bg_template);
        setSelectedAnimation(createdData[0].scene_style_template);
        setImageId(createdData[0].scene_image);
        setSceneType(createdData[0].scene_type);
        setSelectedAvatar(createdData[0].scene_avatar);
        if (createdData[0].scene_image === "") {
          setSelectedFileList([]);
        } else {
          // Incase of content slide - make it a 'CONTENT_SLIDE_WITH_IMAGE'
          if (sceneType == "CONTENT_SLIDE")
            setSceneType("CONTENT_SLIDE_WITH_IMAGE");
        }
        // Do additional processing here, e.g., show a success message, update local state, etc.
        setLoading(false);
        setIsSceneModalOpen(false);
      }
    } catch (error) {
      // Handle errors here
      console.error("Error creating scene:", error);
      // Optionally show an error message
    }
  };

  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const showPopconfirm = (sceneId) => {
    setOpen(true);
    setDeleteSelectedScene(sceneId);
  };

  const handleDeleteCancel = () => {
    console.log("Clicked cancel button");
    setOpen(false);
    setDeleteSelectedScene(null);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const options = [
    {
      value: "CODE_SLIDE",
      label: "Code",
    },
    {
      value: "CONTENT_SLIDE",
      label: "Content",
    },
    {
      value: "CONTENT_SLIDE_WITH_IMAGE",
      label: "Content with Image",
    },
    {
      value: "TITLE_SLIDE",
      label: "Title",
    },
  ];

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <div className={styles.wrapper} ref={localRef}>
      {status === "succeeded" ? (
        <>
          <div ref={leftColRef} className={styles.contnetSection}>
            <div className={styles.slides}>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <SwitcherOutlined
                  style={{ marginRight: "8px", marginLeft: "10px" }}
                />
                <VaTitle level={5} text="Scenes" />
                <Popover content={helpSceneContent} title="Help">
                  <QuestionCircleOutlined
                    style={{
                      marginLeft: "8px",
                      fontSize: "12px",
                      cursor: "pointer",
                    }}
                  />
                </Popover>
              </div>

              <div style={{ display: "flex" }}>
                <div className={styles.scenesList}>
                  {scenesData.map((item, index) => (
                    <div
                      className={styles.sceneWrapper}
                      onClick={() => handleCardClick(item)}
                    >
                      <VaText text={`Scene ${index + 1}`} />
                      <div
                        className={`${styles.scenes} ${
                          selectedScene &&
                          selectedScene.scene_id === item.scene_id
                            ? styles.active
                            : ""
                        }`}
                      >
                        <div className={styles.closeIconWrapper}>
                          <Popconfirm
                            title="Delete Scene"
                            description={`Do you to delete the Scene: ${item.topic_title}.`}
                            open={deleteSelectedScene === item.scene_id}
                            onConfirm={(event) =>
                              deleteScene(event, item?.scene_id)
                            }
                            okButtonProps={{
                              loading: confirmLoading,
                            }}
                            onCancel={handleDeleteCancel}
                          >
                            <Button
                              type="text"
                              icon={<CloseCircleOutlined />}
                              onClick={() => showPopconfirm(item?.scene_id)}
                              className={styles.closeButton}
                            />
                          </Popconfirm>
                        </div>
                        <div className={styles.imageContainer}>
                          <Image
                            preview={false}
                            src={require(`../../../asset/images/background/${item.scene_bg_template}.gif`)}
                          />
                          <div className={styles.centeredText}>
                            {item.scene_title}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={styles.sceneAdd}>
                  <Button
                    shape="circle"
                    type="primary"
                    icon={<PlusOutlined />}
                    // onClick={addNewScene} // Function to handle adding a new scene
                    className={styles.addNewSceneButton}
                    // style={{ marginRight: '8px', marginLeft: '10px' }}
                    onClick={showSceneModal}
                  ></Button>
                </div>
              </div>
            </div>
            <div className={styles.preview}>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <PlaySquareOutlined
                  style={{ marginRight: "8px", marginLeft: "10px" }}
                />
                <VaTitle
                  level={5}
                  text={"Scene " + (selectedSceneIndex + 1) + " - Preview"}
                />
              </div>
              <div
                ref={preiewRef}
                style={{
                  // width: '1980px',
                  // height: '1080px',
                  // maxHeight: '60rem',
                  transform: `scale(${scale})`,
                  transformOrigin: "top left", // Adjust the origin as needed
                  backgroundColor: "#f0f0f0", // Example background
                  // overflow: 'hidden', // Prevent overflow
                  marginLeft: `-${negativeMargin}%`,
                  marginTop: `-${negativeMargin}%`,
                }}
              >
                {/* {scenesData.map((itemData, i) => ( */}
                <div className={styles.remotionPlayer}>
                  <Thumbnail
                    component={sceneTypeToComponent[sceneType]}
                    compositionWidth={1280}
                    compositionHeight={720}
                    frameToDisplay={1000}
                    durationInFrames={1200}
                    fps={30}
                    style={{
                      width: "100%",
                    }}
                    inputProps={{
                      title: selectedTitle,
                      content: handleMarkdownContent(
                        selectedMarkupText,
                        "insert",
                        sceneType
                      ),
                      background: require(`../../../asset/images/background/${selectedBackground}.gif`),
                      animationStyle: selectedAnimation || "fadeIn",
                      imageId: imageId,
                    }}
                  />
                  {selectedAvatar !== null && selectedAvatar !== "" ? (
                    <div className={styles.avatar}>
                      <img
                        src={require(`../../../asset/images/avatar/${selectedAvatar}.jpg`)}
                        alt="Avatar"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.contnetActions}>
            <div>
              <Form.Item label="Scene Title:">
                <Input
                  value={selectedTitle}
                  onChange={handleSceneTitleChange}
                />
              </Form.Item>
              <div>
                <Form.Item label="Scene Type:">
                  <Select
                    // mode="multiple"
                    style={{
                      width: "100%",
                    }}
                    // disabled
                    // placeholder="Select one scene type"
                    // defaultValue={['china']}
                    value={sceneType}
                    onChange={handleSceneTypeChange}
                    options={options}
                  />
                </Form.Item>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <div className={styles.tabs}>
                <Tabs defaultActiveKey="1" items={itemsTextComp} />
              </div>
            </div>

            <div className={styles.textArea}></div>
            <div className={styles.tabs}>
              <Tabs defaultActiveKey="1" items={items} />
            </div>
            <Button
              variant="primary"
              onClick={handleApplyAll}
              disabled={isApplyAllDisabled} // This controls the button's disabled state
              style={{
                backgroundColor: isApplyAllDisabled ? "#ccc" : "darkorange", // Optional: Change color when disabled
                color: "white",
                paddingLeft: "25px",
                paddingRight: "25px",
                cursor: isApplyAllDisabled ? "not-allowed" : "pointer", // Change cursor when disabled
              }}
            >
              Apply All
            </Button>
          </div>
        </>
      ) : (
        <div
          style={{
            textAlign: "center",
            position: "relative",
            height: "600px",
            left: "260px",
          }}
        >
          <Spin
            size="large"
            style={{ alignContent: "center", marginTop: "250px" }}
            className="spinner-container"
          />
          <div style={{ marginTop: "20px", fontSize: "16px", color: "#000" }}>
            Just a sec! It’s almost ready for you!
          </div>
        </div>
      )}
      <Modal
        title="Create New Scene"
        open={isSceneModalOpen}
        onCancel={handleCancel}
        maskClosable={false} // Prevents closing on click outside
        footer={[
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "16px",
              marginTop: "20px",
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setActionType("GenerateByAI");
                form.submit();
              }}
            >
              AI Generate
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setActionType("CustomGenerate");
                form.submit();
              }}
            >
              Custom Generate
            </Button>
          </div>,
        ]}
      >
        <div className={styles.formWrapper}>
          <Form
            name="createScene"
            form={form}
            onFinish={onFinishScene}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Enter your Scene Title:"
              name="sceneName"
              rules={[
                {
                  required: true,
                  message: "Please enter your Scene Name!",
                },
              ]}
            >
              <Input placeholder="Eg: Learn ChatGPT" />
            </Form.Item>
            <Form.Item
              label="Enter your Scene Type:"
              name="sceneType"
              rules={[
                {
                  required: true,
                  message: "Please select your Scene Type!",
                },
              ]}
            >
              <Select
                mode="multiple"
                style={{
                  width: "100%",
                }}
                placeholder="Select one scene type"
                // defaultValue={['china']}
                onChange={handleChange}
                options={options}
              />
            </Form.Item>
          </Form>
        </div>
        {loading && (
          <div className={styles.loaderContainer}>
            <Spin
              size="large"
              // style={{ display: 'block', margin: '0 auto', marginTop: '200px' }}
            />
            <p>Topic is Generating...</p>
          </div>
        )}
      </Modal>
    </div>
  );
});

export default Generate;
