import React from 'react';
import styles from './generate.module.scss';
import { Input } from 'antd';
import { VaTitle } from '../typography';
import { FileTextOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
const { TextArea } = Input;

const helpContent = (
  <div>
    <p>
      Hey there! Feel free to <strong>edit</strong> and <strong>view </strong>
      your
    </p>
    <p>text based on your needs.</p>
  </div>
);

export default function ContentText({
  selectedMarkupText,
  onMarkupTextChange
}) {
  console.log(selectedMarkupText);

  return (
    <div className={styles.audioWrapper}>
      <div className={styles.contnetActions}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <FileTextOutlined style={{ marginRight: '8px' }} />
          <VaTitle level={5} text="Content Text" />
          <Popover content={helpContent} title="Help">
            <QuestionCircleOutlined
              style={{
                marginLeft: '300px',
                fontSize: '16px',
                cursor: 'pointer'
              }}
            />
          </Popover>
        </div>
        <div className={styles.audioText}>
          <TextArea
            rows={8}
            placeholder="maxLength is 6"
            value={selectedMarkupText}
            name="sceneMarkupText"
            onChange={(event) => onMarkupTextChange(event)}
          />
        </div>
      </div>
    </div>
  );
}
