import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { makeApiRequest } from '../../../shared/api';

export const getUsageQuota = createAsyncThunk(
  'course/getUsageQuota',
  async (topicId, thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', {
        action: 'billing_get_quota'
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const billingSlice = createSlice({
  name: 'billing',
  initialState: {
    usageQuota: null,
    status: 'idle',
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsageQuota.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUsageQuota.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.usageQuota = action.payload;
      })
      .addCase(getUsageQuota.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export default billingSlice.reducer;
