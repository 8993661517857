import React, { useEffect, useState } from 'react';
import styles from './billing.module.scss';
import { VaText, VaTitle } from 'shared/components/typography';
import { Button, Progress, Spin, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getUsageQuota } from './redux/billingSlice';

export default function Billings() {
  const dispatch = useDispatch();
  const [balanceQuota, setBalanceQuota] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { usageQuota, status, error } = useSelector((state) => state.billing);

  useEffect(() => {
    dispatch(getUsageQuota());
  }, [dispatch]);

  useEffect(() => {
    if (status === 'succeeded' && usageQuota) {
      let balance = usageQuota?.data?.balance;
      balance = balance / 60;
      setBalanceQuota(balance);
    }
    if (balanceQuota <= 0) {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
    }
  }, [balanceQuota]);
  // Handle the OK button or any action in the modal
  const handleOk = () => {
    setIsModalVisible(false);
    // Optionally, redirect to an upgrade page or take other actions here
  };

  return (
    <div className={styles.wrapper}>
      <VaTitle level={5} text="Billing - Usage Report" />
      {status === 'succeeded' ? (
        <div className={styles.card}>
          <div className={styles.header}>
            <VaText text="Free Plan" />
            <Button>$0 / Month</Button>
          </div>
          <div className={styles.contnet}>
            <Progress percent={(balanceQuota / 30) * 100} status="active" />
            <VaText text={`${balanceQuota} minutes / 30 minutes`} />
          </div>
          <div className={styles.footer}>
            <Button type="primary">Upgrade</Button>
          </div> 
          {/* Modal shown when balanceQuota is 0 */}
          <Modal
            title="Upgrade"
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleOk}
            okText="Ok"
          >
            <p>You've hit your video generation limit! Upgrade now to continue creating.</p>
          </Modal>
        </div>
      ) : (
        <div style={{ textAlign: 'center', height: '500px' }}>
          <Spin
            size="large"
            className="spinner"
            style={{ display: 'block', margin: '0 auto', marginTop: '200px' }}
          />
          <div style={{ marginTop: '20px' }}>
            <h3>Loading, please wait...</h3>
          </div>
        </div>
      )}
    </div>
  );
}
