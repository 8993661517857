import React, { useContext } from 'react';
import {
  createBrowserRouter,
  Navigate,
  createRoutesFromElements,
  Route,
  RouterProvider
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { VaLayout } from './layouts';
import { VaContext } from 'shared/rbac/context';
import store from 'shared/store';
import Login from 'pages/account/login';
import Courses from 'pages/courses';
import CourseIndex from 'pages/courseIndex';
import CreateAccount from 'pages/account/create';
import ForgotPassword from 'pages/account/forgotpassword';
import Settings from 'pages/settings';
import Billings from 'pages/billing';
import ChangePassword from 'pages/account/changepassword';
import VerifyAccount from 'pages/account/verify';

function RootRouter() {
  const authCxt = useContext(VaContext);
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route index path="/" element={<Login />} />
        <Route
          index
          path="/account/create-account"
          element={<CreateAccount />}
        />
        <Route
          index
          path="/account/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          index
          path="/account/change-password"
          element={<ChangePassword />}
        />
        <Route index path="/account/verify" element={<VerifyAccount />} />
        <Route index path="/account/login" element={<Login />} />
        <Route
          path="/"
          element={
            authCxt?.authenticated ? (
              <VaLayout />
            ) : (
              <Navigate to="/account/login" />
            )
          }
        >
          <Route path="/courses" element={<Courses />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/billings" element={<Billings />} />
          <Route path="/courses/:courseId" element={<CourseIndex />} />
          <Route path="/profile" element={<Settings />} /> {/* 404 Route */}
        </Route>
        <Route path="/account/changepassword" element={<Settings />} /> {/* 404 Route */}
      </>
    )
  );

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
}
export default RootRouter;
