import React, { useState, useEffect, useRef } from 'react';
import {
  VideoCameraOutlined,
  SmileOutlined,
  FilePdfOutlined,
  ReadOutlined,
  VideoCameraFilled,
  QuestionCircleOutlined,
  FilePptOutlined,
  FileTextOutlined
} from '@ant-design/icons';
import { Popover } from 'antd';
import {
  Button,
  Modal,
  Form,
  Input,
  Spin,
  Image,
  Result,
  Select,
  Upload,
  Tabs,
  message
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import CoursesList from 'shared/components/coursesList';
import VideosList from 'shared/components/coursesList/videosList';
import styles from './courses.module.scss';
import { VaTitle } from 'shared/components/typography';
import Generate from 'shared/components/generate';
import svg404 from './404.svg';
import Preview from 'shared/components/generate/preview';

import {
  getCoursesList,
  getCourseById,
  createCourse,
  getVideosList,
  clearCourseState
} from './redux/courseSlice';
import {
  createVideo,
  generateVideoByText
} from '../courseIndex/redux/topicContentSlice';

export default function Courses() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const generateRef = useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isGenerateDisabled, setIsGenerateDisabled] = useState(false);
  const [isPptDisabled, setIsPptDisabled] = useState(false);
  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);
  const [isShortVideoModalOpen, setIsShortVideoModalOpen] = useState(false);
  const [isGenerateModalOpen, setIsGenerateModalOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [videoName, setVideoName] = useState('');
  const [selectedShortData, setSelectedShortData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [actionType, setActionType] = useState('');
  const [shortActionType, setShortActionType] = useState('');

  const {
    coursesListStatus,
    coursesList,
    videosListStatus,
    videosList,
    coursesListError,
    videosListError
  } = useSelector((state) => state.course);
  console.log(coursesListStatus, coursesList, coursesListError);
  console.log(videosListStatus, videosList, videosListError);
  const { status, course, error } = useSelector((state) => state.course);
  console.log(status, course, error);

  const { TabPane } = Tabs;
  const { Dragger } = Upload;

  useEffect(() => {
    if (
      !isShortVideoModalOpen &&
      !isGenerateModalOpen &&
      !isCourseModalOpen &&
      !isPreviewModalOpen
    ) {
      dispatch(getCoursesList());

      const fetchVideos = () => {
        console.log('Fetching videos');
        dispatch(getVideosList());
      };

      // Fetch videos immediately on mount
      fetchVideos();

      // Set an interval to fetch videos every 1 minute (60000 milliseconds)
      const intervalId = setInterval(() => {
        fetchVideos();
      }, 180000);

      // Clear the interval when the component unmounts
      return () => {
        console.log('Clearing interval');
        clearInterval(intervalId);
      };
    }
  }, [
    dispatch,
    isShortVideoModalOpen,
    isGenerateModalOpen,
    isCourseModalOpen,
    isPreviewModalOpen
  ]);

  useEffect(() => {
    dispatch(clearCourseState());
  }, []);

  const openCourse = (data) => {
    console.log(data);
    // dispatch(getCourseById({ course_id: data?.course_id }));
    navigate(`/courses/${data?.course_id}`);
  };

  const showCourseModal = () => {
    setIsCourseModalOpen(true);
  };

  const showShortVideoModal = () => {
    setIsButtonDisabled(true); // Disable the button when the modal opens
    setIsShortVideoModalOpen(true);
  };

  const handleCancelCourse = () => {
    setIsCourseModalOpen(false);
  };

  const handleCancelShortVideo = () => {
    setIsShortVideoModalOpen(false);
  };

  const showGenerateModal = () => {
    setIsGenerateModalOpen(true);
  };
  const showHelpModal = () => {
    setIsHelpModalOpen(true);
  };

  const handleHelpModalCancel = () => {
    setIsHelpModalOpen(false);
  };

  const handleGenerateCancel = () => {
    setIsGenerateModalOpen(false);
    dispatch(getVideosList());
  };

  const handleGenerateCancelBtn = () => {
    setIsGenerateModalOpen(false);
  };
  const showPreviewModal = () => {
    setIsPreviewModalOpen(true);
  };

  const handlePreviewCancel = () => {
    setIsPreviewModalOpen(false);
  };

  const shortData = (data) => {
    setSelectedShortData(data);
  };

  const onFinishCourse = async (values) => {
    let courseName = values.courseName;
    setVideoName(courseName);
    setLoading(true); // Start loading

    try {
      // let data = await dispatch(createCourse({ topic: courseName }));

      const resultAction = await dispatch(createCourse({ topic: courseName }));
      console.log(resultAction);
      console.log(status, course, error);

      // Check if the action was successful
      if (createCourse.fulfilled.match(resultAction)) {
        // Extract course ID from the payload
        console.log(resultAction);
        const courseId = resultAction?.payload?.data?.course?.course_id; // Adjust according to your response structure

        console.log('Course Created Successfully:', resultAction.payload);

        // Navigate to the new course page using the course ID
        navigate(`/courses/${courseId}`);

        // Optionally, you can refresh the course list
        dispatch(getCoursesList());

        // Optionally handle UI state changes or modal closures
        handleCancelCourse();
      } else {
        // Handle errors if needed
        console.error('Failed to create course:', resultAction.payload);
      }
    } catch (error) {
      console.error('Error creating course:', error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen(true);
  const handleCancel = () => setIsModalOpen(false);

  const uploadProps = {
    name: 'file',
    multiple: false,
    action: '/upload', // Replace with your upload handling endpoint
    beforeUpload: (file) => {
      const isFileAllowed =
        file.type === 'application/pdf' ||
        file.type === 'application/vnd.ms-powerpoint';
      if (!isFileAllowed) {
        message.error('You can only upload PDF or PPT files!');
      }
      return isFileAllowed || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFinishShortVideo = (values) => {
    console.log('video name----->>>>>');
    const videoName = values.courseName;
    let ai_generate = false;

    if (shortActionType === 'shortGenerateByAI') {
      ai_generate = true;
      // Handle "Generate by AI" logic
      console.log('Generate by AI:', values);
      const videoName = values.courseName;
      setVideoName(videoName);
      dispatch(createVideo({ topic: videoName, ai_generate: "true" }));
      handleCancelShortVideo();
      showGenerateModal();
      dispatch(getVideosList());
    } else if (shortActionType === 'shortCustomGenerate') {
      // Handle "Custom Generate" logic
      console.log('Generate by AI:', values);
      const videoName = values.courseName;
      setVideoName(videoName);
      dispatch(createVideo({ topic: videoName, ai_generate: "false" }));
      handleCancelShortVideo();
      showGenerateModal();
      dispatch(getVideosList());
    }
  };

  const generateVideoContent = () => {
    console.log('Update Content-is triggered>>>>>>>>');
    if (generateRef.current) {
      generateRef.current.generateVideoContent(); // Call the method exposed by Generate component
      handlePreviewCancel();
    }
  };
  const updateScene = () => {
    if (generateRef.current) {
      generateRef.current.updateScene(); // Call the method exposed by Generate component
      setIsButtonDisabled(true); // Optionally disable the button again after updating
      setIsGenerateDisabled(false);
      setIsPptDisabled(false);
    }
  };

    const handleBothActions = () => {
      setIsGenerateDisabled(false);
      generateVideoContent(); // Generates video content
      handleGenerateCancelBtn(); // Closes the modal
    };

  const generatePptContent = () => {
      setIsPptDisabled(false)
    if (generateRef.current) {
      generateRef.current.generatePptContent();
    }
  };

  const helpContent = (
    <div>
      <p>
        Check out the <strong>Short Video</strong> or{' '}
        <strong>Course Video</strong> to take
      </p>
      <p> first step for creating a video.</p>
    </div>
  );
  const helpvideoContent = (
    <div>
      <p>
        Please click this help before <strong>generating </strong> the video
      </p>
      <p> first step for creating a video.</p>
    </div>
  );

  const loadCourseVideoDetails = () => {
    if (
      (coursesListStatus === 'succeeded' &&
        coursesList?.data?.courses?.length > 0) ||
      (videosListStatus === 'succeeded' && videosList?.data?.videos?.length > 0)
    ) {
      return (
        <>
          {coursesListStatus === 'succeeded' &&
            coursesList?.data?.courses?.length > 0 && (
              <div className={styles.featuredCourse}>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <ReadOutlined style={{ marginRight: '8px' }} />
                  <VaTitle level={4} text="Your Courses" />
                </div>
                <div className={styles.list}>
                  <CoursesList
                    data={coursesList?.data?.courses}
                    openCourse={openCourse}
                  />
                </div>
              </div>
            )}
          {videosListStatus === 'succeeded' &&
            videosList?.data?.videos?.length > 0 && (
              <div className={styles.featuredCourse}>
                <div style={{ display: 'flex', alignItems: 'baseline' }}>
                  <VideoCameraFilled style={{ marginRight: '8px' }} />
                  <VaTitle level={4} text="Shorts Videos" />
                </div>
                <div className={styles.list}>
                  <VideosList
                    data={videosList?.data?.videos}
                    isProgress={false}
                    showPreviewModal={showPreviewModal}
                    showGenerateModal={showGenerateModal}
                    videoName={setVideoName}
                    shortData={shortData}
                  />
                </div>
              </div>
            )}
        </>
      );
    } else {
      return (
        <Result
          style={{ overflow: 'hidden' }}
          icon={<Image height={300} preview={false} src={svg404} />}
          title={
            <div>
              <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <Button
                  type="primary"
                  style={{
                    borderRadius: '10px',
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                    cursor: 'pointer',
                    backgroundColor: 'green',
                    border: 'none'
                  }}
                  onClick={showHelpModal}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = 'darkorange')
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = 'green')
                  }
                >
                  WorkFlow Video
                </Button>
              </div>
              <h6 className={styles.titleH6} style={{ textWrap: 'nowrap' }}>
                No content available at the moment.<br></br>Click the button to
                generate a video!
              </h6>
            </div>
          }
          extra={
            <Modal
              title="Help Video"
              open={isHelpModalOpen}
              onCancel={handleHelpModalCancel}
              footer={null} // No footer buttons
            >
              <div style={{ textAlign: 'center' }}>
                <iframe
                  width="100%"
                  height="340px"
                  src="https://www.youtube.com/embed/vFbeUsTMHh8"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </Modal>
          }
        />
      );
    }
  };
  const handleUserInteraction = () => {
    setIsButtonDisabled(false); // Enable the button after any user interaction
    setIsGenerateDisabled(true);
    setIsPptDisabled(true);
  };

  const options = [
    {
      value: 'CODE_SLIDE',
      label: 'Code'
    },
    {
      value: 'CONTENT_SLIDE',
      label: 'Content'
    },
    {
      value: 'CONTENT_SLIDE_WITH_IMAGE',
      label: 'Content with Image'
    },
    {
      value: 'CONTENT_SLIDE_WITH_VIDEO',
      label: 'Content with Video'
    },
    {
      value: 'CONTENT_SLIDE_WITH_VIDEO_ONLY',
      label: 'Video'
    },
    {
      value: 'TITLE_SLIDE',
      label: 'Title'
    }
  ];

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onFinishTextToShortVideo = (values) => {
    console.log('video name----->>>>>');
    const videoName = values.courseName;
    let restrict = true;

    if (actionType === 'generateByAI') {
      restrict = false;
      // Handle "Generate by AI" logic
      console.log('Generate by AI:', values);
      const videoText = values.videoText;
      setVideoName(videoName);
      dispatch(generateVideoByText({ topic: videoName, text: videoText, restrict }));
      handleCancelShortVideo();
      showGenerateModal();
      dispatch(getVideosList());
    } else if (actionType === 'customGenerate') {
      // Handle "Custom Generate" logic
      console.log('Custom Generate:', values);
      const videoText = values.videoText;
      setVideoName(videoName);
      dispatch(generateVideoByText({ topic: videoName, text: videoText, restrict }));
      handleCancelShortVideo();
      showGenerateModal();
      dispatch(getVideosList());
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.toolBar}>
        <VaTitle level={4} text="" />
        <div className={styles.sort}>
          <Button
            onClick={showShortVideoModal}
            icon={<VideoCameraOutlined />}
            type="primary"
          >
            Short Video
          </Button>
          <Button
            onClick={showCourseModal}
            icon={<VideoCameraOutlined />}
            type="primary"
          >
            Course Video
          </Button>
          <Button
            onClick={showModal}
            icon={<FileTextOutlined />}
            type="primary"
          >
            Text to Video
          </Button>
          <Popover
            content={helpContent}
            title={<strong style={{ color: '#1890ff' }}>Helpful Tips</strong>}
            overlayStyle={{
              borderRadius: '10px',
              boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
              backgroundColor: '#f0f0f0'
            }}
            trigger="hover"
          >
            <QuestionCircleOutlined
              style={{
                fontSize: '20px',
                cursor: 'pointer',
                color: '#1890ff',
                transition: 'color 0.3s ease-in-out'
              }}
              onMouseEnter={(e) => (e.currentTarget.style.color = '#ff6f00')}
              onMouseLeave={(e) => (e.currentTarget.style.color = '#1890ff')}
            />
          </Popover>
          {/* <Button
            onClick={(event) => generateContent(event, 'FileToVideo')}
            icon={<FilePdfOutlined />}
            type="primary"
          >
            PDF to Video
          </Button> */}
        </div>
      </div>
      {coursesListStatus === 'succeeded' || videosListStatus === 'succeeded' ? (
        <>{loadCourseVideoDetails()}</>
      ) : (
        <div style={{ textAlign: 'center', height: '500px' }}>
          <Spin
            size="large"
            className="spinner"
            style={{ display: 'block', margin: '0 auto', marginTop: '200px' }}
          />
          <div style={{ marginTop: '20px' }}>
            <h3>Loading, please wait...</h3>
          </div>
        </div>
      )}

      {/* Course Video Modal */}
      <Modal
        title="Generate Your Own Course"
        open={isCourseModalOpen}
        onCancel={handleCancelCourse}
        maskClosable={false} // Prevents closing on click outside
        footer={[
          <Button key="back" onClick={handleCancelCourse}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={() => form.submit()}>
            Generate
          </Button>
        ]}
      >
        <div className={styles.formWrapper}>
          <Form
            name="generateCourse"
            form={form}
            onFinish={onFinishCourse}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Enter your Course Name:"
              name="courseName"
              rules={[
                {
                  required: true,
                  message: 'Please enter Course Name!'
                },
                {
                  max: 50,
                  message: 'Topic name cannot exceed 50 characters!'
                }
              ]}
            >
              <Input placeholder="Eg: Learn ChatGPT" />
            </Form.Item>
          </Form>
        </div>
        {loading && (
          <div className={styles.loaderContainer}>
            <Spin
              size="large"
            // style={{ display: 'block', margin: '0 auto', marginTop: '200px' }}
            />
            <p>Course Structure is Generating...</p>
          </div>
        )}
      </Modal>

      {/* Short Video Modal */}
      <Modal
        title="Generate Your Short Video"
        open={isShortVideoModalOpen}
        onCancel={handleCancelShortVideo}
        maskClosable={false} // Prevents closing on click outside
        footer={[
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '16px',
              marginTop: '20px'
            }}
          >
            <Button
              type="primary"
              onClick={() => {
                setShortActionType('shortGenerateByAI');
                form.submit();
              }}
            >
              AI Generate
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setShortActionType('shortCustomGenerate');
                form.submit();
              }}
            >
              Custom Generate
            </Button>
          </div>
        ]}
      >
        <div className={styles.formWrapper}>
          <Form
            name="generateShortVideo"
            form={form}
            onFinish={onFinishShortVideo}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <Form.Item
              label="Enter your Topic name:"
              name="courseName"
              rules={[
                {
                  required: true,
                  message: 'Please enter Topic Name!'
                },
                {
                  max: 50,
                  message: 'Topic name cannot exceed 50 characters!',
                },
              ]}
            >
              <Input placeholder="Eg: What is Generative AI" />
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <Modal
        title="Generate Your Video Content"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <FileTextOutlined />
                Text to Video
              </span>
            }
            key="1"
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinishTextToShortVideo}
            // onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Enter Title:"
                name="courseName"
                rules={[{ required: true, message: 'Please enter a title!' }]}
              >
                <Input placeholder="Title of your video content" />
              </Form.Item>
              <Form.Item
                label="Enter Text:"
                name="videoText"
                rules={[
                  { required: true, message: 'Please enter text content!' }
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Text to convert into video"
                />
              </Form.Item>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '16px',
                  marginTop: '20px'
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setActionType('generateByAI');
                    form.submit();
                  }}
                >
                  AI Generate
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setActionType('customGenerate');
                    form.submit();
                  }}
                >
                  Custom Generate
                </Button>
              </div>
            </Form>
          </TabPane>
          {/* <TabPane
            tab={<span><FilePdfOutlined />PDF to Video</span>}
            key="2"
          >
            <Dragger {...uploadProps} accept=".pdf">
              <p className="ant-upload-drag-icon">
                <FilePdfOutlined />
              </p>
              <p className="ant-upload-text">Click or drag PDF to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', marginTop:'20px' }}>
                <Button type="primary" onClick={() => form.submit()}>
                  Generative AI
                </Button>
                <Button type="primary" onClick={() => form.submit()}>
                  Custom Text
                </Button>
              </div>
          </TabPane>
          <TabPane
            tab={<span><FilePptOutlined />PPT to Video</span>}
            key="3"
          >
            <Dragger {...uploadProps} accept=".ppt,.pptx">
              <p className="ant-upload-drag-icon">
                <FilePptOutlined />
              </p>
              <p className="ant-upload-text">Click or drag PPT to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '16px', marginTop:'20px' }}>
                <Button type="primary" onClick={() => form.submit()}>
                  Generative AI
                </Button>
                <Button type="primary" onClick={() => form.submit()}>
                  Custom Text
                </Button>
              </div>
          </TabPane> */}
        </Tabs>
      </Modal>

      {/* Generate Modal */}
      <Modal
        title={videoName}
        open={isGenerateModalOpen}
        onCancel={handleGenerateCancel}
        width={'99vw'}
        centered
        height={'99vh'}
        maskClosable={false} // Prevents closing on click outside
        footer={[
          <Button key="cancel" onClick={handleGenerateCancel}>
            Cancel
          </Button>,
          <Button
            key="back"
            onClick={updateScene}
            disabled={isButtonDisabled}
            type="primary"
          >
            Update Scene
          </Button>,
          <Button key="submit" type="primary" onClick={handleBothActions} disabled={isGenerateDisabled}>
            Generate Video
          </Button>,
          <Button key="submit" type="primary" onClick={generatePptContent} disabled={isPptDisabled}>
            Download PPT
          </Button>
        ]}
      >
        <div
          className={styles.formWrapper}
          onClick={handleUserInteraction}
          onKeyUp={handleUserInteraction}
        >
          <Generate ref={generateRef} />
        </div>
      </Modal>

      {/* Preview Modal */}
      <Modal
        id="video-panel"
        title={videoName}
        open={isPreviewModalOpen}
        width={'99vw'}
        centered
        height={'99vh'}
        onCancel={handlePreviewCancel}
        destroyOnClose={true}
        footer={[]}
        maskClosable={false} // Prevents closing on click outside
      >
        <div className={styles.formWrapper}>
          <Preview selectedShortData={selectedShortData} />
        </div>
      </Modal>
    </div>
  );
}