import React from 'react';
import styles from './generate.module.scss';
import { Image, Empty } from 'antd';

export default function Animations({ onAvatarSelect, selectedAvatar }) {
  const imageData = [
    {
      id: 0, // Special ID for the "None" option
      image_name: 'none',
      src: '' // Empty source for "None" option
    },
    {
      id: 1,
      image_name: 'avatar_1'
    },
    {
      id: 2,
      image_name: 'avatar_2'
    },
    {
      id: 3,
      image_name: 'avatar_3'
    }
  ];

  return (
    <div className={styles.backgroundWrapper}>
      {imageData.map((item) => (
        <div
          key={item.id}
          className={`${styles.bgCard} ${
            selectedAvatar === item.image_name ? styles.selected : ''
          }`}
          onClick={() => onAvatarSelect(item)}
        >
          {item.image_name === 'none' ? (
            <div className={styles.noneOption}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
          ) : (
            <Image
              preview={false}
              src={require(`../../../asset/images/avatar/${item.image_name}.jpg`)}
            />
          )}
        </div>
      ))}
    </div>
  );
}
