import styled from 'styled-components';

export const CustomOrderedList = styled.ol`
  text-align: left;
  margin-block-start: 0em !important;
  margin-block-end: 0.5em !important;
  li {
    padding-bottom: 10px;
  }
`;

export const CustomUnorderedList = styled.ul`
  text-align: left;
  margin-block-start: 0em !important;
  margin-block-end: 0.5em !important;
  li {
    padding-bottom: 10px;
  }
`;

export const CustomParaList = styled.p`
  margin-block-start: 0em !important;
  margin-block-end: 0.5em !important;
  text-align: left;
`;
export const CustomCodeList = styled.code`
  margin-block-start: 0em !important;
  margin-block-end: 0.5em !important;
  text-align: left;
  padding: 4px 24px !important;
  margin: 0px 0px 0.5em 0px !important;
`;
