import React from "react";
import { Layout } from 'antd';
import { useLocation } from 'react-router';
import {
  PayCircleOutlined,
  SettingOutlined,
  HomeOutlined
} from '@ant-design/icons';

import styles from './layouts.module.scss';
import { Link } from "react-router-dom";

const { Sider } = Layout;

function GlobalSider(props) {
  const { background } = props;
  const { pathname } = useLocation();

  const items = [
    {
      key: 'courses',
      icon: <HomeOutlined />,
      link: "/courses"
    },
    {
      key: 'settings',
      icon: <SettingOutlined />,
      link: '/settings'
    },
    {
      key: 'billing',
      icon: <PayCircleOutlined />,
      link: '/billings'
    }
  ];


  return (
    <Sider
      style={{
        background: background
      }}
      className={styles.sideNavWrapper}
      trigger={null}
    >
      <ul className={styles.memu}>
        {items.map((menu, i) =>
          <li key={menu.key} className={pathname.indexOf(menu.key) > -1 ? styles.active : ''}>
            <div className={styles.item}>
              <Link to={menu.link}>
                {menu.icon}
              </Link>
            </div>
          </li>
        )}
      </ul>

    </Sider>


  );
}

export default GlobalSider;