const theme = {
  "token": {
    "colorPrimary": "#fa8c16",
    "colorInfo": "#fa8c16",
    "components": {
      "Layout": {
        "bodyBg": "rgb(244, 246, 248)"
      }
    }
  }
};

export {theme};
