import React, { useCallback, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Image, Input } from 'antd';
import { VaContext } from 'shared/rbac/context';
import toast from 'react-hot-toast';
import { accountAPI } from '../account.service';
import { VaTitle } from 'shared/components/typography';
import logo from './logo.svg';

import styles from './signin.module.scss';

function ForgotPassword() {
  const [form] = Form.useForm();
  const authCxt = useContext(VaContext);
  let navigate = useNavigate();
  const [, setLoading] = useState(false);

  const handleFinish = useCallback((values) => {
    setLoading(true);
    accountAPI.login(values).then(
      (data) => {
        toast.success(`Login Successful! Welcome back ${values.email}!`);
        setLoading(false);
        authCxt.handleAuthentication(data);
        if (!data?.isVerified) {
          navigate('/account/verify');
        } else {
          navigate('/courses');
        }
      },
      (err) => {
        console.log(err);

        setLoading(false);
        if (err.__type === 'InvalidPasswordException') {
          const passwordPolicyMessage = `
      Password must meet the following criteria:
      - At least 8 characters long
      - At least one uppercase letter
      - At least one lowercase letter
      - At least one numeric character
      - At least one special character (e.g., !@#$%^&*)
    `;
          toast.error(passwordPolicyMessage, {
            duration: 8000,
            style: { whiteSpace: 'pre-line' }
          });
        } else {
          toast.error(
            err.message ||
              'An error occurred during sign-up. Please try again later.'
          );
        }
        return;
      }
    );
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <div className={styles.logo}>
          <Image height={40} src={logo} preview={false} />
          <VaTitle
            className={styles.title}
            level={5}
            text="Forgot your password?"
          />
        </div>
        <Form
          name="basic"
          layout="vertical"
          onFinish={handleFinish}
          form={form}
          autoComplete="off"
        >
          <Form.Item
            label="Work email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your Work email!'
              }
            ]}
          >
            <Input placeholder="Enter email" />
          </Form.Item>
        </Form>
        <div className={styles.footer}>
          <Button onClick={() => form.submit()} type="primary">
            Reset
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
