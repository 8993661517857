import React, { useEffect, useState } from 'react';
import { deleteCookie } from 'cookies-next';
import { UserOutlined, SearchOutlined } from '@ant-design/icons';
import { Layout, Button, Dropdown, Image, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import logo from './logo.svg';
import styles from './layouts.module.scss';

import userPool from 'AWSCognitoUserPool';

const { Header } = Layout;

function GlobalHeader(props) {
  const navigate = useNavigate();
  const [userInitial, setUserInitial] = useState(' '); // Default initial
  
  // Assuming you can get the user's email from a method like getCurrentUser() or a global state
  useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.error(err);
          return;
        }
        const userEmail = session.getIdToken().payload.email;
        setUserInitial(userEmail.charAt(0).toUpperCase());
      });
    }
  }, []);

  const logout = ({ key }) => {
    if (key === '3') {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.signOut();
        deleteCookie('accessToken', '', 0);
        navigate('/');
      }
    }
  };

  const options = [
    {
      key: '2',
      label: <Link to="/account/changepassword">Change password</Link>,
      icon: <UserOutlined />
    },
    {
      type: 'divider'
    },
    {
      key: '3',
      label: 'Logout',
      icon: <UserOutlined />
    }
  ];

  const SearchInput = () => {
    return (
      <div
        key="SearchOutlined"
        aria-hidden
        className={styles.search}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Input
          className={styles.searchBox}
          prefix={<SearchOutlined />}
          placeholder="Search courses"
          variant={'borderless'}
        />
        {/* <PlusCircleOutlined /> */}
      </div>
    );
  };

  return (
    <>
      <Header className={styles.shallowHeader}></Header>
      <Header className={`${styles.header}`}>
        <div className={styles.topNavHeader}>
          <div className={styles.topNavHeaderMain}>
            <div
              className={styles.topNavHeaderMainLeft}
              onClick={() => navigate('/courses')}
            >
              <div className={styles.logo}>
                <Image
                  height={38}
                  src={logo}
                  preview={false}
                  style={{ marginTop: '20px' }}
                />
              </div>
              {/* Add VideoAssistant.ai text here */}
              <div className={styles.branding}>
                <h3
                  style={{
                    marginLeft: '100px',
                    marginTop: '-40px',
                    fontFamily: 'inherit',
                    opacity: '90%',
                    fontSize: '20px'
                  }}
                >
                  VideoAssistant.ai
                </h3>
              </div>
            </div>
            <div className={`${styles.topNavHeaderMenu} topNav`}>
              <ul>
                {/* <li className={styles.welcomeText}>Welcome John</li> */}
              </ul>
            </div>
            <div className={styles.headerRightContent}>
              <SearchInput />
            </div>
            <div className={styles.headerActionsAvatar}>
              <Dropdown
                menu={{
                  items: options,
                  onClick: logout
                }}
                overlayClassName={styles.settings}
                arrow
                placement={'bottomRight'}
              >
                <Button shape="circle">{userInitial}</Button>
              </Dropdown>
            </div>
          </div>
        </div>
      </Header>
    </>
  );
}

export default GlobalHeader;
