import React from 'react';
import styles from './courseslist.module.scss';
import { Progress, Image } from 'antd';
import { VaTitle } from '../typography';
import { Link } from 'react-router-dom';

export default function CoursesList(props) {
  const { data, isProgress, openCourse } = props;
  console.log(data);

  return (
    <div className={styles.wrapper}>
      {data.map((item) => (
        <Link
          to={`/courses/${item?.course_id}`}
          key={item.id}
          className={styles.card}
          onClick={() => openCourse(item)}
        >
          <Image
            preview={false}
            src={
              item?.thumbnail_image
                ? `https://va-sc-images.s3.amazonaws.com/${item?.thumbnail_image}`
                : require(`../../../asset/images/course.png`)
            }
          />
          <div className={styles.footer}>
            <div className={styles.title}>
              <VaTitle
                level={5}
                ellipsis={{
                  rows: 2
                }}
                text={item?.title}
              />
            </div>
            {isProgress && (
              <div className={styles.actions}>
                <Progress percent={30} />
              </div>
            )}
          </div>
        </Link>
      ))}
    </div>
  );
}
