import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { makeApiRequest } from '../../../shared/api';
import { Modal } from 'antd';  // Import Modal
import { Popconfirm } from 'antd';

export const generateTopicContent = createAsyncThunk(
  'course/generateTopicContent',
  async (topicName, thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', {
        action: 'generate_course_video',
        ...topicName
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const showModal = (message) => {
  Modal.error({
    title: 'Error',
    content: message,
  });
};

export const updateTopicContent = createAsyncThunk(
  'course/updateTopicContent',
  async (topicData, thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', {
        action: 'update_video',
        ...topicData
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateCourseContent = createAsyncThunk(
  'course/updateCourseContent',
  async (topicData, thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', {
        action: 'update_course',
        ...topicData
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const generateVideo = createAsyncThunk(
  'course/generateVideo',
  async (topicId, thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', {
        action: 'generate_video_bg',
        ...topicId
      });
      
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getVideoById = createAsyncThunk(
  'topic/getVideoById',
  async (videoData, thunkAPI) => {
    console.log(videoData);
    try {
      const response = await makeApiRequest('POST', {
        action: 'get_video',
        ...videoData
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const createVideo = createAsyncThunk(
  'topic/createVideo',
  async (videoData, thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', {
        action: 'generate_video',
        ...videoData
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteScenes = createAsyncThunk(
  'topic/deleteScenes',
  async (sceneData, thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', {
        action: 'delete_scene',
        ...sceneData
      });
      console.log(response);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const createScenes = createAsyncThunk(
  'topic/createScenes',
  async (sceneData, thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', {
        action: 'generate_scene',
        ...sceneData
      });
      console.log(response);

      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const generateVideoByText = createAsyncThunk(
  'course/generateVideoByText',
  async (courseParam, thunkAPI) => {
    try {
      const response = await makeApiRequest('POST', {
        action: 'generate_video_with_text',
        ...courseParam
      });
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const topicContentSlice = createSlice({
  name: 'topic',
  initialState: {
    topicData: null,
    status: 'idle',
    error: null,
    updateError: null,
    updatedTopicData: null,
    updateStatus: 'idle',

    generateVideoData: null,
    generateVideoStatus: 'idle',
    generateVideoError: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateTopicContent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(generateTopicContent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.topicData = action.payload;
      })
      .addCase(generateTopicContent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(generateVideo.pending, (state) => {
        state.generateVideoStatus = 'loading';
      })
      .addCase(generateVideo.fulfilled, (state, action) => {
        state.generateVideoStatus = 'succeeded';
        state.generateVideoData = action.payload;
        console.log("-----------------------------------------------------");
        console.log(state.generateVideoData);
        console.log(state.generateVideoData.data);
        console.log("-----------------------------------------------------");
        if (
          "Insufficient Quota: User does not have sufficient credits to generate video. Contact Admin." === state.generateVideoData.data
        ) {
          showModal("Insufficient Quota: User does not have sufficient credits to generate video. Contact Admin.");
        }
      })
      .addCase(generateVideo.rejected, (state, action) => {
        state.generateVideoStatus = 'failed';
        state.generateVideoError = action.payload;
      })
      .addCase(updateTopicContent.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateTopicContent.fulfilled, (state, action) => {
        state.updateStatus = 'succeeded';
        state.updatedTopicData = action.payload;
      })
      .addCase(updateTopicContent.rejected, (state, action) => {
        state.updateStatus = 'failed';
        state.updateError = action.payload;
      })
      .addCase(createVideo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createVideo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.topicData = action.payload;
      })
      .addCase(createVideo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(getVideoById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getVideoById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.topicData = action.payload;
      })
      .addCase(getVideoById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateCourseContent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCourseContent.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.topicData = action.payload;
      })
      .addCase(updateCourseContent.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(generateVideoByText.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(generateVideoByText.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.topicData = action.payload;
      })
      .addCase(generateVideoByText.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  } 
});

export default topicContentSlice.reducer;
