import RemotionTextSlide from './RemotionTextSlide';
import RemotionTextImageSlide from './RemotionTextImageSlide';
import RemotionImageSlide from './RemotionImageSlide';
import RemotionCodeSlide from './RemotionCodeSlide';
import RemotionTitleSlide from './RemotionTitleSlide';

export const sceneTypeToComponent = {
  CODE_SLIDE: RemotionCodeSlide,
  CONTENT_SLIDE: RemotionTextSlide,
  CONTENT_SLIDE_WITH_IMAGE: RemotionTextImageSlide,
  IMAGE_ONLY_SLIDE: RemotionImageSlide,
  TITLE_SLIDE: RemotionTitleSlide
};
